<template>
  <div class="van-main has-fixed-btn">
    <div class="van-common-detail">
      <van-tabs v-model="activeName">
        <van-tab name="info" title="企业准入客户信息">
          <van-cell-group title="企业信息">
            <van-cell title="企业全称" :value="utils.isEffectiveCommon(accessDetail.orgName)" />
            <van-cell title="企业简称" :value="utils.isEffectiveCommon(accessDetail.orgShortName)" />
            <van-cell title="成立日期" :value="utils.isEffectiveCommon(accessDetail.establishDate)" />
            <van-cell title="统一社会信用代码" :value="utils.isEffectiveCommon(accessDetail.orgNo)" />
            <van-cell title="注册资本（万元）" :value="utils.moneyFormat(accessDetail.orgRegCap)" />
            <van-cell title="企业联系电话" :value="utils.isEffectiveCommon(accessDetail.orgContactPhone)" />
            <van-cell title="注册地址" :value="utils.newProvinceCityFormatter([accessDetail.orgRegProvince,accessDetail.orgRegCity, accessDetail.orgRegXq, accessDetail.orgRegDetail])" />
            <van-cell title="办公地址" :value="utils.newProvinceCityFormatter([accessDetail.orgWorkProvince,accessDetail.orgWorkCity, accessDetail.orgWorkXq, accessDetail.orgWorkDetail])" />
          </van-cell-group>
          <van-cell-group title="企业法定代表人信息">
            <van-cell title="法定代表人姓名" :value="utils.isEffectiveCommon(accessDetail.businessName)" />
            <van-cell title="证件类型" :value="utils.statusFormat(accessDetail.businessSnType, 'businessSnType')" />
            <van-cell title="电子邮箱" :value="utils.isEffectiveCommon(accessDetail.businessEmail)" />
            <van-cell title="法定代表人联系电话" :value="utils.isEffectiveCommon(accessDetail.bizPhone)" />
            <van-cell title="证件号码" :value="utils.isEffectiveCommon(accessDetail.businessSn)" />
            <!--            <van-cell title="企业录入人" :value="utils.isEffectiveCommon()" />-->
          </van-cell-group>
          <van-cell-group title="账户余额">
            <van-cell title="保证金余额（元）" :value="utils.moneyFormat()" />
            <van-cell title="历史盈余金额（元）" :value="utils.moneyFormat()" />
          </van-cell-group>
        </van-tab>
        <van-tab name="material" title="准入资料">
          <div class="table goods-table">
            <table class="inner-goods-table">
              <thead>
                <tr>
                  <th>名称</th>
                  <th>上传人</th>
                  <th>附件</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="3">
                    一、证照材料
                  </td>
                </tr>
                <tr v-for="item in material.licence" :key="item.materialId">
                  <td>{{ utils.isEffectiveCommon(item.materialName) }}</td>
                  <td>{{ utils.isEffectiveCommon(item.uploadUserName) }}</td>
                  <td>
                    <van-uploader v-model="item.fileList" :show-upload="false" :deletable="false" disabled @click-preview="preview" />
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    二、财税资料
                  </td>
                </tr>
                <tr v-for="item in material.finance" :key="item.materialId">
                  <td>{{ utils.isEffectiveCommon(item.materialName) }}</td>
                  <td>{{ utils.isEffectiveCommon(item.uploadUserName) }}</td>
                  <td>
                    <van-uploader v-model="item.fileList" :show-upload="false" :deletable="false" disabled @click-preview="preview" />
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    三、公司资料
                  </td>
                </tr>
                <tr v-for="item in material.company" :key="item.materialId">
                  <td>{{ utils.isEffectiveCommon(item.materialName) }}</td>
                  <td>{{ utils.isEffectiveCommon(item.uploadUserName) }}</td>
                  <td>
                    <van-uploader v-model="item.fileList" :show-upload="false" :deletable="false" disabled @click-preview="preview" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </van-tab>
        <van-tab name="report" title="尽调报告">
          <van-cell-group title="尽调报告">
            <van-cell title="企业全称" :value="utils.isEffectiveCommon(accessDetail.orgName)" />
            <van-cell title="统一社会信用代码" :value="utils.isEffectiveCommon(accessDetail.orgNo)" />
            <van-cell title="被列入严重违法失信企业名单" :value="utils.trueFalseFormant(reportDetail.trustworthy)" />
            <van-cell title="被列入经营异常名单" :value="utils.trueFalseFormant(reportDetail.abnormal)" />
            <van-cell title="被列为被执行人" :value="utils.trueFalseFormant(reportDetail.implementer)" />
            <file-list :file-list="reportDetail.fileList" title="尽调报告" />
          </van-cell-group>
        </van-tab>
        <van-tab name="quota" title="额度信息">
          <van-cell-group title="本次额度信息">
            <van-cell title="产品类型">
              <van-checkbox-group v-model="quotaDetail.productType" disabled class="detail-checkbox-group">
                <van-checkbox v-for="item in constants.ProjectProductType" :key="item.value" :name="item.value">
                  {{ item.label }}
                </van-checkbox>
              </van-checkbox-group>
            </van-cell>
            <van-cell title="合作额度(万元)" :value="utils.moneyFormat(quotaDetail.quota)" />
            <van-cell title="合作期限(月)" :value="utils.isEffectiveCommon(quotaDetail.term)" />
            <van-cell title="额度起止日" :value="`${utils.dataString(quotaDetail.quotaDateStart)} - ${utils.dataString(quotaDetail.quotaDateEnd)}`" />
          </van-cell-group>
        </van-tab>
        <van-tab name="file" title="相关附件">
          <attachment-list module-name="ADMIT" :business-id="$route.query.businessId" category="1" />
        </van-tab>
        <van-tab name="audit" title="审批意见">
          <workflow-audit :page-key="utils.checkSystemType(constants.systemType.gzbSystem)?'ENTERPRISE_ADMIT':'enterprise_access'" :business-key="$route.query.businessKey" :task-id="$route.query.taskId" :proc-inst-id="$route.query.procInstId" :variable="{businessId:$route.query.businessId,motif: $route.query.motif, dataSources: $route.query.dataSources}" @confirmAudit="utils.backReturn($route.query.messageType)" />
        </van-tab>
      </van-tabs>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-button type="info" color="#1373CC" block text="返回" @click="utils.backReturn($route.query.messageType)" />
    </van-row>
  </div>
</template>

<script>
import { Tab, Tabs, Cell, CellGroup, Row, Button, Uploader, Checkbox, CheckboxGroup } from 'vant'
import fileList from '@/components/file-list'
import AttachmentList from '@/components/attachment-list'
import WorkflowAudit from '@/components/workflow-audit'
export default {
  components: {
    WorkflowAudit,
    AttachmentList,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    fileList
  },
  data () {
    return {
      activeName: 'info',
      accessDetail: {},
      material: {
        licence: [],
        finance: [],
        company: []
      },
      reportDetail: {},
      quotaDetail: {}
    }
  },
  created () {
    this.getCompanyDetail()
    this.getCompanyMaterialDetail()
    this.getQuotaDetail()
    this.getCompanyInspectDetail()
  },
  methods: {
    getCompanyDetail () {
      this.utils.loading()
      this.api.task.companyAccess.enterpriseModifyDetail(this.$route.query.enterpriseId).then(result => {
        this.accessDetail = result.data.value
      }).finally(() => {
        this.utils.closeLoading()
      })
    },
    getCompanyMaterialDetail () {
      this.api.task.companyAccess.enterpriseMaterialDetail(this.$route.query.admitId).then(result => {
        result.data.value.forEach(item => {
          item.fileList = []
          if (item.fileDetailInfoVo) {
            const { fileName: name, fileUrl: url } = item.fileDetailInfoVo
            item.fileDetailInfoVo = this._.assign(item.fileDetailInfoVo, { name, url })
            item.fileList.push(item.fileDetailInfoVo)
          }
          switch (item.materialType) {
            case 1:
              this.material.licence.push(item)
              break
            case 2:
              this.material.finance.push(item)
              break
            case 3:
              this.material.company.push(item)
              break
          }
        })
      })
    },
    getCompanyInspectDetail () {
      this.api.task.companyAccess.enterpriseInspectDetail(this.$route.query.admitId).then(result => {
        this.reportDetail = result.data.value
        const report = this.reportDetail.report.split(',')
        this.reportDetail.fileList = []
        if (!this._.isEmpty(report)) {
          report.forEach(item => {
            this.utils.getDetailByFileId(item).then(result => {
              this.reportDetail.fileList.push(result)
            })
          })
        }
      })
    },
    getQuotaDetail () {
      this.api.task.companyAccess.enterpriseQuotaDetail(this.$route.query.admitId).then(result => {
        const data = result.data.value
        if (data.productType) {
          data.productType = data.productType.split(',')
        } else {
          data.productType = []
        }
        this.quotaDetail = data
      })
    },
    preview (file, detail) {
      const fileTypeArr = file.fileType.split('/')
      if (fileTypeArr[0] !== 'image') {
        window.open(file.url)
      }
    }
  }
}
</script>

<style scoped>

</style>
