var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main has-fixed-btn" },
    [
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "van-tab",
                { attrs: { name: "info", title: "企业准入客户信息" } },
                [
                  _c(
                    "van-cell-group",
                    { attrs: { title: "企业信息" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "企业全称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.orgName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "企业简称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.orgShortName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "成立日期",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.establishDate
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "统一社会信用代码",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.orgNo
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "注册资本（万元）",
                          value: _vm.utils.moneyFormat(
                            _vm.accessDetail.orgRegCap
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "企业联系电话",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.orgContactPhone
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "注册地址",
                          value: _vm.utils.newProvinceCityFormatter([
                            _vm.accessDetail.orgRegProvince,
                            _vm.accessDetail.orgRegCity,
                            _vm.accessDetail.orgRegXq,
                            _vm.accessDetail.orgRegDetail
                          ])
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "办公地址",
                          value: _vm.utils.newProvinceCityFormatter([
                            _vm.accessDetail.orgWorkProvince,
                            _vm.accessDetail.orgWorkCity,
                            _vm.accessDetail.orgWorkXq,
                            _vm.accessDetail.orgWorkDetail
                          ])
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "van-cell-group",
                    { attrs: { title: "企业法定代表人信息" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "法定代表人姓名",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.businessName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "证件类型",
                          value: _vm.utils.statusFormat(
                            _vm.accessDetail.businessSnType,
                            "businessSnType"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "电子邮箱",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.businessEmail
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "法定代表人联系电话",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.bizPhone
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "证件号码",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.businessSn
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "van-cell-group",
                    { attrs: { title: "账户余额" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "保证金余额（元）",
                          value: _vm.utils.moneyFormat()
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "历史盈余金额（元）",
                          value: _vm.utils.moneyFormat()
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "van-tab",
                { attrs: { name: "material", title: "准入资料" } },
                [
                  _c("div", { staticClass: "table goods-table" }, [
                    _c("table", { staticClass: "inner-goods-table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("名称")]),
                          _c("th", [_vm._v("上传人")]),
                          _c("th", [_vm._v("附件")])
                        ])
                      ]),
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _c("td", { attrs: { colspan: "3" } }, [
                              _vm._v(" 一、证照材料 ")
                            ])
                          ]),
                          _vm._l(_vm.material.licence, function(item) {
                            return _c("tr", { key: item.materialId }, [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      item.materialName
                                    )
                                  )
                                )
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      item.uploadUserName
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "td",
                                [
                                  _c("van-uploader", {
                                    attrs: {
                                      "show-upload": false,
                                      deletable: false,
                                      disabled: ""
                                    },
                                    on: { "click-preview": _vm.preview },
                                    model: {
                                      value: item.fileList,
                                      callback: function($$v) {
                                        _vm.$set(item, "fileList", $$v)
                                      },
                                      expression: "item.fileList"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          }),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "3" } }, [
                              _vm._v(" 二、财税资料 ")
                            ])
                          ]),
                          _vm._l(_vm.material.finance, function(item) {
                            return _c("tr", { key: item.materialId }, [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      item.materialName
                                    )
                                  )
                                )
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      item.uploadUserName
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "td",
                                [
                                  _c("van-uploader", {
                                    attrs: {
                                      "show-upload": false,
                                      deletable: false,
                                      disabled: ""
                                    },
                                    on: { "click-preview": _vm.preview },
                                    model: {
                                      value: item.fileList,
                                      callback: function($$v) {
                                        _vm.$set(item, "fileList", $$v)
                                      },
                                      expression: "item.fileList"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          }),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "3" } }, [
                              _vm._v(" 三、公司资料 ")
                            ])
                          ]),
                          _vm._l(_vm.material.company, function(item) {
                            return _c("tr", { key: item.materialId }, [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      item.materialName
                                    )
                                  )
                                )
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      item.uploadUserName
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "td",
                                [
                                  _c("van-uploader", {
                                    attrs: {
                                      "show-upload": false,
                                      deletable: false,
                                      disabled: ""
                                    },
                                    on: { "click-preview": _vm.preview },
                                    model: {
                                      value: item.fileList,
                                      callback: function($$v) {
                                        _vm.$set(item, "fileList", $$v)
                                      },
                                      expression: "item.fileList"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          })
                        ],
                        2
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "van-tab",
                { attrs: { name: "report", title: "尽调报告" } },
                [
                  _c(
                    "van-cell-group",
                    { attrs: { title: "尽调报告" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "企业全称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.orgName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "统一社会信用代码",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.orgNo
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "被列入严重违法失信企业名单",
                          value: _vm.utils.trueFalseFormant(
                            _vm.reportDetail.trustworthy
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "被列入经营异常名单",
                          value: _vm.utils.trueFalseFormant(
                            _vm.reportDetail.abnormal
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "被列为被执行人",
                          value: _vm.utils.trueFalseFormant(
                            _vm.reportDetail.implementer
                          )
                        }
                      }),
                      _c("file-list", {
                        attrs: {
                          "file-list": _vm.reportDetail.fileList,
                          title: "尽调报告"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "van-tab",
                { attrs: { name: "quota", title: "额度信息" } },
                [
                  _c(
                    "van-cell-group",
                    { attrs: { title: "本次额度信息" } },
                    [
                      _c(
                        "van-cell",
                        { attrs: { title: "产品类型" } },
                        [
                          _c(
                            "van-checkbox-group",
                            {
                              staticClass: "detail-checkbox-group",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.quotaDetail.productType,
                                callback: function($$v) {
                                  _vm.$set(_vm.quotaDetail, "productType", $$v)
                                },
                                expression: "quotaDetail.productType"
                              }
                            },
                            _vm._l(_vm.constants.ProjectProductType, function(
                              item
                            ) {
                              return _c(
                                "van-checkbox",
                                {
                                  key: item.value,
                                  attrs: { name: item.value }
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c("van-cell", {
                        attrs: {
                          title: "合作额度(万元)",
                          value: _vm.utils.moneyFormat(_vm.quotaDetail.quota)
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "合作期限(月)",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.quotaDetail.term
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "额度起止日",
                          value:
                            _vm.utils.dataString(
                              _vm.quotaDetail.quotaDateStart
                            ) +
                            " - " +
                            _vm.utils.dataString(_vm.quotaDetail.quotaDateEnd)
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "van-tab",
                { attrs: { name: "file", title: "相关附件" } },
                [
                  _c("attachment-list", {
                    attrs: {
                      "module-name": "ADMIT",
                      "business-id": _vm.$route.query.businessId,
                      category: "1"
                    }
                  })
                ],
                1
              ),
              _c(
                "van-tab",
                { attrs: { name: "audit", title: "审批意见" } },
                [
                  _c("workflow-audit", {
                    attrs: {
                      "page-key": _vm.utils.checkSystemType(
                        _vm.constants.systemType.gzbSystem
                      )
                        ? "ENTERPRISE_ADMIT"
                        : "enterprise_access",
                      "business-key": _vm.$route.query.businessKey,
                      "task-id": _vm.$route.query.taskId,
                      "proc-inst-id": _vm.$route.query.procInstId,
                      variable: {
                        businessId: _vm.$route.query.businessId,
                        motif: _vm.$route.query.motif,
                        dataSources: _vm.$route.query.dataSources
                      }
                    },
                    on: {
                      confirmAudit: function($event) {
                        return _vm.utils.backReturn(
                          _vm.$route.query.messageType
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
        [
          _c("van-button", {
            attrs: { type: "info", color: "#1373CC", block: "", text: "返回" },
            on: {
              click: function($event) {
                return _vm.utils.backReturn(_vm.$route.query.messageType)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }